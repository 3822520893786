body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* TODO переписать на flex */
main {
  min-height: calc(100vh - 343px);
  position: 'relative';
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #9aa4b5;
  border: 1px solid #9aa4b5;
}

/*TODO: рефакторинг*/
@media screen and (max-width: 768px) {
  html {
    max-width: 100%;
    overflow: hidden;
  }
  body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  position: relative;
  overflow: hidden;
}

.CustomContainerClassSlider {
  height: 170px;
  align-items: flex-start !important;
  padding-left: 3px;
  margin-left: -3px;
}

@media (max-width: 767px) {
  .CustomContainerClassSlider {
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  .CustomContainerClassSlider {
    margin-bottom: -45px;
  }
}

@media (max-width: 500px) {
  .CustomContainerClassSlider {
    margin-bottom: -65px;
    margin-top: 20px;
    overflow: inherit !important;
    padding-left: 30px;
    margin-left: -30px;
  }
}
