.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.datesContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.transaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.transactionMainInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}