.react-datepicker {
  display: flex;
  flex-direction: column;
  border: none;
  color: #475467;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 10px;
  font-family: Inter, sans-serif;
  padding: 16px;
  box-shadow: 0px 2px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 16px;
  position: absolute;
  left: -15px;
  width: 280px;
  background: #fff;
  z-index: 99;
}

.react-datepicker-popper {
  z-index: 99;
}

.react-datepicker__navigation {
  top: 22px;
  position: absolute;
  width: 20px;
  height: 30px;
  border: 0;
  background: transparent;
  cursor: pointer;
  &--previous {
    left: 24px;
    &:before {
      background: url("./ArrowLeft.svg") no-repeat center center;
      right: 0;
    }
  }
  &--next {
    right: 24px;
    &:before {
      background: url("./ArrowRight.svg") no-repeat center center;
      left: 0;
      position: absolute;
      content: "";
      width: 20px;
      height: 30px;
      top: 0;
      z-index: 1;
    }
  }
  &:before {
    position: absolute;
    content: "";
    width: 20px;
    height: 30px;
    top: 0;
    z-index: 1;
  }
}

.react-datepicker__aria-live {
  display: none;
}

.react-datepicker__day-names,
.react-datepicker__day-name {
  color: #0082ba;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker__day,
.react-datepicker__day-name {
  // margin: 0 0 2px 0;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  box-sizing: border-box;
  font-size: 14px;
}

.react-datepicker__week {
  display: flex;
  color: #0082ba;
  // width: 343px;
  // justify-content: space-between;
}

.react-datepicker__day {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker__day:hover {
  background-color: #0082ba;
  border-radius: 40px;
  color: #fff;
}
.react-datepicker__day--today {
  color: #475467;
  font-weight: 400;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day--outside-month {
  color: #9aa4b5;
}

.react-datepicker__header {
  background-color: white;
  border-bottom: none;
}

.react-datepicker__day-names {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  // height: 64px;
  font-size: 14px;
  text-transform: capitalize;
}

.react-datepicker__current-month {
  text-transform: capitalize;
  background-color: #f2f4f9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 12px 24px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  color: #393e46;
  font-weight: inherit;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: white;
  color: #475467;
  border-radius: 0;
  font-weight: 400;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #ebedf3;
  color: #475467;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #ebedf3;
  border-radius: 40px;
  color: #475467;
}

.react-datepicker__day--in-range {
  border-radius: 40px;
  background-color: #ebedf3;
  color: #475467;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #0082ba;
  border-radius: 40px;
  color: #fff;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: #ebedf3;
}
