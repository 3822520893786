.main {
  width: 90px;
  height: 54px;
  border-radius: 10px;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topBlock {
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}